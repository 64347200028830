import type { SearchRequest, SearchSort } from '@opensearch-project/opensearch/api/types';
import type { Customer, Prisma } from '@prisma/client';
import { withZod } from '@remix-validated-form/with-zod';
import bbox from '@turf/bbox';
import type { BBox, Position } from 'geojson';
import type { FeatureCollection, GeoJSON } from 'geojson';
import debounce from 'lodash/debounce';
import type { FillLayer, LineLayer, LngLatBounds, MapboxGeoJSONFeature, MapLayerMouseEvent } from 'mapbox-gl';
import ngeohash from 'ngeohash';
import type { RefObject } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { MapRef } from 'react-map-gl';
import { z } from 'zod';

import { getIndexName } from '~/utils/constituent-index';
import type { OpenSearchFilter } from '~/utils/constituents-data-filters';

// public key for mapbox
export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiYmphY29ic28taW5kaWdvdiIsImEiOiJjbDloaGloMmk0NHY1M3VxbXczOHplbHlwIn0.7dvWUYmbHXMfev8tjJ6bOQ';

export const mapStyleLinks = () => [
{ rel: 'stylesheet', href: require('mapbox-gl/dist/mapbox-gl.css') },
{ rel: 'stylesheet', href: require('@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css') }];


export const defaultBoundingBox = {
  sw: { lng: -97.06814415341178, lat: 32.70458340786979 },
  ne: { lng: -95.89677540012245, lat: 33.40792972623848 }
};

export const defaultMapStyle = 'mapbox://styles/mapbox/streets-v9';

const latLngSchema = z.object({
  lng: z.number(),
  lat: z.number()
});

type TopLeftLat = number;
type TopLeftLon = number;
type BottomRightLat = number;
type BottomRightLon = number;

export type IBBox = [TopLeftLat, TopLeftLon, BottomRightLat, BottomRightLon];

type BucketItem = {key: string;doc_count: number;};
export type GridResult = {
  body?: {
    aggregations?: {
      grid?: {
        buckets?: BucketItem[];
      };
    };
    hits?: {
      total?:
      number |
      {
        value?: number;
      };
    };
  };
};

export const mapSchema = z.object({
  query: z.string().default('*:*'),
  precision: z.preprocess((a) => parseInt(z.string().default('5').parse(a), 10), z.number().positive().max(100)),
  zoom: z.preprocess((a) => parseFloat(z.string().default('6').parse(a)), z.number().positive().max(100)),
  bbox: z.preprocess(
    (a) => a ? deserializeBoundingBox((a as string)) : undefined,
    z.object({
      sw: latLngSchema,
      ne: latLngSchema
    })
  )
});

export const mapSchemaValidator = withZod(mapSchema);

export type MapSchema = z.infer<typeof mapSchema>;

export const growBbox = (bbox: BBox, growth: number) => {
  const [a, b, c, d] = bbox;
  const xgrowth = (a - c) * growth;
  const ygrowth = (b - d) * growth;
  return [a + xgrowth, b + ygrowth, c - xgrowth, d - ygrowth];
};

export const fixBbox = (bbox: BBox): BBox => {
  const [a, b, c, d] = bbox;
  return [b, a, d, c];
};

type GridType = 'square' | 'hexagon';
// convert zoom into opensearch precision
export const getPrecision = (zoom: number, gridType: GridType = 'square') => {
  const minZoom = 4;
  const maxZoom = 16;

  const zoomPercent = (zoom - minZoom) / (maxZoom - minZoom);

  let minPrecision = 4;
  let maxPrecision = 9;

  if (gridType === 'hexagon') {
    minPrecision = 5;
    maxPrecision = 13;
  }

  const precision = minPrecision + (maxPrecision - minPrecision) * zoomPercent;
  return Math.round(precision);
};

// lng = x
// lat = y

export interface MapBBox {
  sw: {
    lng: number;
    lat: number;
  };
  ne: {
    lng: number;
    lat: number;
  };
}

export const getBoundingBox = (geojson: FeatureCollection): MapBBox => {
  const [a, b, c, d] = bbox(geojson);
  return {
    sw: {
      lng: a,
      lat: b
    },
    ne: {
      lng: c,
      lat: d
    }
  };
};

export const growBoundingBox = (bbox: MapBBox, growth: number): MapBBox => {
  const latGrowth = Math.abs(Math.abs(bbox.sw.lat) - Math.abs(bbox.ne.lat)) * growth;
  const lngGrowth = Math.abs(Math.abs(bbox.sw.lng) - Math.abs(bbox.ne.lng)) * growth;
  return {
    sw: {
      lng: bbox.sw.lng - lngGrowth,
      lat: bbox.sw.lat - latGrowth
    },
    ne: {
      lng: bbox.ne.lng + lngGrowth,
      lat: bbox.ne.lat + latGrowth
    }
  };
};

export const mapboxBoundsToBoundingBox = (bounds: LngLatBounds): MapBBox => {
  // const nw = bounds.getNorthWest()
  // const se = bounds.getSouthEast()
  // return [nw.lat, nw.lng, se.lat, se.lng].join(',')
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  return {
    sw: {
      lng: sw.lng,
      lat: sw.lat
    },
    ne: {
      lng: ne.lng,
      lat: ne.lat
    }
  };
};

export const serializeBoundingBox = (bbox: MapBBox): string => {
  return [bbox.sw.lng, bbox.sw.lat, bbox.ne.lng, bbox.ne.lat].join(',');
};

export const deserializeBoundingBox = (input: string): MapBBox => {
  const [a, b, c, d] = input.split(',').map((x) => parseFloat(x));
  return {
    sw: {
      lng: a,
      lat: b
    },
    ne: {
      lng: c,
      lat: d
    }
  };
};

export const dataLayer: FillLayer = {
  id: 'data',
  type: 'fill',
  paint: {
    'fill-color': {
      property: 'doc_count',
      stops: [
      // Colors from mapbox example:
      // [1, '#3288bd'],
      // [10, '#66c2a5'],
      // [100, '#abdda4'],
      // [1000, '#e6f598'],
      // [10000, '#ffffbf']

      // Branded colors from:
      // https://carto.com/carto-colors/
      // https://blog.datawrapper.de/colorguide/
      // https://www.vis4.net/palettes/#/5|s|4338ca,fdba74|ffffe0,ff005e,93003a|1|1

      [1, '#3730a3'],
      [10, '#7b4e9b'],
      [100, '#aa7091'],
      [1000, '#d59484'],
      [10000, '#fdba74']]

    },
    'fill-opacity': 0.8
  }
};

export const maskLayer: FillLayer = {
  id: 'mask',
  type: 'fill',
  source: 'boundary',
  layout: {},
  paint: {
    'fill-color': '#ffffff',
    'fill-opacity': 0.4
  }
};

export const outlineLayer: LineLayer = {
  id: 'outline',
  type: 'line',
  source: 'boundary',
  layout: {},
  paint: {
    'line-color': '#4b4949',
    'line-width': 1,
    'line-opacity': 0.8
  }
};

export const useResizeMap = (mapRef: RefObject<MapRef>) => {
  const resizeMap = debounce(() => {
    mapRef.current?.resize();
  }, 400);

  useEffect(() => {
    window.addEventListener('resize', resizeMap);
    return () => {
      window.removeEventListener('resize', resizeMap);
    };
  }, [mapRef, resizeMap]);

  return { resizeMap };
};

export type HoverInfo = {
  feature?: MapboxGeoJSONFeature;
  x: number;
  y: number;
};

export type SelectInfo = {
  feature: MapboxGeoJSONFeature;
  x: number;
  y: number;
  numPointsSelected?: number;
};

export const useHoverInfo = () => {
  const [hoverInfo, setHoverInfo] = useState<HoverInfo>();
  const [selectInfo, setSelectInfo] = useState<SelectInfo>();
  const [numPolygonPoints, setNumPolygonPoints] = useState(0);

  const onHover = useCallback((event: MapLayerMouseEvent, isDrawing = false) => {
    const {
      features,
      point: { x, y }
    } = event;

    const hoveredFeature = features && features[0];
    if (isDrawing || hoveredFeature) {
      setHoverInfo({
        feature: hoveredFeature,
        x,
        y
      });
    }
  }, []);

  const onSelect = useCallback(() => {
    setHoverInfo(undefined);
    setNumPolygonPoints((prevState) => prevState ? prevState + 1 : 1);
    hoverInfo?.feature && setSelectInfo((hoverInfo as SelectInfo));
  }, [hoverInfo]);

  const removeSelect = () => {
    setNumPolygonPoints(0);
    setSelectInfo(undefined);
  };
  const removeHover = () => {
    setNumPolygonPoints(0);
    setHoverInfo(undefined);
  };

  return {
    hoverInfo,
    numPolygonPoints,
    selectInfo,
    onHover,
    onSelect,
    removeHover,
    removeSelect
  };
};

export const geoJsonToSearchFilter = (geoJson: Prisma.JsonValue | GeoJSON.FeatureCollection) => {
  const filters = ((geoJson as unknown) as GeoJSON.FeatureCollection).features.reduce<
    Array<{
      geo_polygon: {
        address_geo: {
          points: Array<Position>;
        };
      };
    }>>(
    (accum, feature) => {
      // For each Polygon, add a new filter to the array.
      if (feature.geometry.type === 'Polygon') {
        for (const lngLats of feature.geometry.coordinates) {
          accum.push({
            geo_polygon: { address_geo: { points: lngLats } }
          });
        }
      }

      // For each MultiPolygon, add a new filter for each polygon.
      if (feature.geometry.type === 'MultiPolygon') {
        for (const polygon of feature.geometry.coordinates) {
          for (const lngLats of polygon) {
            accum.push({
              geo_polygon: { address_geo: { points: lngLats } }
            });
          }
        }
      }

      return accum;
    }, []);

  return {
    bool: {
      should: filters // Use 'should' to match documents in any provided polygon
    }
  };
};

export const addMapDataToFormData = (formData: FormData, mapData: MapData) => {
  if (mapData.bbox) {
    formData.set('bbox', serializeBoundingBox(mapData.bbox));
  }
  if (mapData.zoom) {
    formData.set('zoom', mapData.zoom.toString());
  }
  if (mapData.precision) {
    formData.set('precision', mapData.precision.toString());
  }
  if (mapData.geoFilter) {
    formData.set('geo_filter', JSON.stringify(mapData.geoFilter));
  }
};

export type MapData = {
  bbox?: MapBBox;
  zoom?: number;
  precision?: number;
  geoFilter?: FeatureCollection;
  selectedBounds?: string;

  drawMode?: 'simple_select' | 'draw_polygon' | string;
};

export const transformResultToGeoJSON = (result: GridResult) => {
  const features = result?.body?.aggregations?.grid?.buckets?.map((bucket) => {
    const geo = ngeohash.decode_bbox(bucket?.key);
    const [lat1, lon1, lat2, lon2] = geo;

    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
        // transform lat/lons into polygon
        [
        [lon2, lat2],
        [lon2, lat1],
        [lon1, lat1],
        [lon1, lat2],
        [lon2, lat2]]]


      },
      properties: {
        key: bucket?.key,
        doc_count: bucket?.doc_count
      }
    };
  });

  return {
    type: 'FeatureCollection',
    features: features
  };
};

export const buildSearchRequest = ({
  customer,
  filters,
  bbox,
  precision,
  page = 1,
  perPage = 10,
  searchAfter,
  sort









}: {customer?: Pick<Customer, 'id'>;filters: OpenSearchFilter[];bbox?: MapBBox;precision?: number;page?: number;perPage?: number;searchAfter?: string | null;sort?: SearchSort;}): SearchRequest => {
  return {
    index: getIndexName(customer || 'l2'),
    body: {
      from: (page - 1) * perPage,
      size: perPage,
      track_total_hits: true,
      sort: sort || [
      {
        _id: {
          order: 'asc'
        }
      }],

      ...(searchAfter && { search_after: [searchAfter] }),
      query: {
        bool: {
          must: [],
          filter: filters
        }
      },
      aggregations: {
        ...(precision &&
        bbox && {
          grid: {
            geohash_grid: {
              field: 'address_geo',
              precision: precision,
              bounds: {
                top_left: {
                  lat: bbox.ne.lat,
                  lon: bbox.sw.lng
                },
                bottom_right: {
                  lat: bbox.sw.lat,
                  lon: bbox.ne.lng
                }
              }
            }
          }
        }),
        // Aggregate the number of results that have an email address
        hasEmail: {
          terms: {
            field: 'email_address_available',
            size: 10
          }
        }
      }
    }
  };
};